import { Fragment, useEffect, useState } from "react";
import { Menu } from "@mui/icons-material";
import { AppBar, Box, IconButton, Toolbar } from "@mui/material";
import { useAccountContext } from "@root/stores/accountStore";
import { observer } from "mobx-react-lite";
import { UserAvatar } from "../common/UserAvatar";
import { MenuDrawer } from "../layout/MenuDrawer";
import { Logo } from "../common/Logo";

export const LoggedInNavigation = observer(() => {
  const accountStore = useAccountContext();
  const [mounted, setMounted] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open: boolean) => {
    setDrawerOpen(open);
  };

  useEffect(() => {
    if (mounted) return;
    setMounted(true);
  }, [mounted]);

  if (!accountStore.account || !accountStore.session || !mounted) return;

  return (
    <Fragment>
      <AppBar sx={{ padding: 0 }}>
        <Toolbar>
          <Box
            sx={(theme) => ({
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              px: "3rem",
              width: "100%",
              position: "relative",
              [theme.breakpoints.down("md")]: {
                px: "1rem",
              },
            })}
          >
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: "1.5rem",
                display: "flex",
                alignItems: "center",
                height: "100%",
                textAlign: "start",
              }}
            >
              <IconButton
                size="large"
                onClick={() => toggleDrawer(!drawerOpen)}
                sx={{ padding: 0 }}
              >
                <Menu sx={(theme) => ({ color: theme.palette.primary.main })} />
              </IconButton>
              <MenuDrawer open={drawerOpen} toggleDrawer={toggleDrawer} />
            </Box>
            <Logo />
            <Box
              sx={{
                position: "absolute",
                top: 0,
                right: "1.5rem",
                display: "flex",
                alignItems: "center",
                height: "100%",
              }}
            >
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={() => accountStore.showSwitchProfileModal()}
                color="inherit"
                sx={{
                  padding: 0,
                  backgroundColor: "transparent !important",
                }}
              >
                <UserAvatar
                  fallbackText={accountStore.session!.initials}
                  imageUrl={
                    accountStore.isUserProfile
                      ? accountStore.account!.imageUrl
                      : undefined
                  }
                />
              </IconButton>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Toolbar />
    </Fragment>
  );
});
