import { createContext, useContext } from "react";
import { makeAutoObservable, runInAction } from "mobx";
import { Account, Coordinate, Session } from "@root/utils/entities";
import { AuthModalStore } from "./authModalStore";
import { Timeout } from "@root/utils/enums";
import { navigate, reload } from "vike/client/router";
import { isBrowser } from "@root/utils/functions";
import { ModalStore } from "./modalStore";

const SESSION_DATA_NAME = "EP-Session";

export class AccountStore {
  readonly authModalStore = new AuthModalStore();
  readonly switchProfileModalStore = new ModalStore();

  account?: Account;
  session?: Session;
  unverifiedEmail?: string;
  isAuthenticated: boolean = false;
  menuExpanded: boolean = false;
  locationRequested: boolean = false;
  errorMessage?: string;
  successMessage?: string;
  userLocation?: Coordinate;

  constructor(account?: Account) {
    makeAutoObservable(this);
    this.account = account;    

    if (this.account) {
      this.session = this.getAccountSession();
    }
  }

  get displayName(): string {
    return `${this.account?.firstName} ${this.account?.lastName}`;
  }

  get profiles(): Session[] {
    if (!this.account) return [];

    return (
      this.account.profiles ?? [
        new Session(this.account!.id, this.displayName, "user"),
      ]
    );
  }

  get isOrganizationProfile() {
    return this.session?.type === "organization";
  }

  get isUserProfile() {
    return this.session?.type === "user";
  }

  async login(account: Account) {
    this.account = account;
    this.session = new Session(this.account!.id, this.displayName, "user");
    this.isAuthenticated = true;

    if (this.account.emailConfirmed) {
      this.setAccountSession(new Session(account.id, this.displayName, "user"));

      if (this.authModalStore.redirectUrl) {
        await navigate(this.authModalStore.redirectUrl);
      } else {
        await reload();
      }
      this.authModalStore.closeAuthModal();
    } else {
      this.unverifiedEmail = account.email;
      this.authModalStore.changeAuthModalType("verification");
    }
  }

  authenticate(email: string) {
    this.isAuthenticated = true;
    this.unverifiedEmail = email;
    this.authModalStore.changeAuthModalType("verification");
  }

  setUnverifiedEmail(email: string) {
    this.unverifiedEmail = email;
    this.isAuthenticated = false;
    this.authModalStore.changeAuthModalType("verification");
  }

  setPendingEmail(email: string) {
    this.unverifiedEmail = email;
    this.authModalStore.changeAuthModalType("verification");
  }

  updateAccountInfo(firstName: string, lastName: string) {
    this.errorMessage = undefined;

    if (this.account) {
      this.account.firstName = firstName;
      this.account.lastName = lastName;
    }
  }

  updateAccountImage(imageUrl?: string) {
    this.errorMessage = undefined;

    if (this.account) {
      this.account.imageUrl = imageUrl;
    }
  }

  updateAccountEmail(email: string) {
    this.errorMessage = undefined;

    if (this.account) {
      this.account.email = email;
    }
  }

  setErrorMessage(message: string) {
    this.errorMessage = message;

    setTimeout(() => {
      runInAction(() => {
        this.clearErrorMessage();
      });
    }, Timeout.SuccessMessage);
  }

  clearErrorMessage() {
    this.errorMessage = undefined;
  }

  setSuccessMessage(message: string) {
    this.successMessage = message;

    setTimeout(() => {
      runInAction(() => {
        this.clearSuccessMessage();
      });
    }, Timeout.SuccessMessage);
  }

  clearSuccessMessage() {
    this.successMessage = undefined;
  }

  toggleMenu() {
    this.menuExpanded = !this.menuExpanded;
  }

  setUserLocation(lat: number, lng: number) {
    this.userLocation = {
      latitude: lat.toString(),
      longitude: lng.toString(),
    };
    this.locationRequested = true;
  }

  clearUserLocation() {
    this.userLocation = undefined;
    this.locationRequested = true;
  }

  setAccountSession(session: Session) {
    if (!isBrowser() || !session) return;

    const json = session.toJSON();
    localStorage.setItem(SESSION_DATA_NAME, JSON.stringify(json));
  }

  getAccountSession(): Session | undefined {
    if (!isBrowser()) return;

    const sessionData = localStorage.getItem(SESSION_DATA_NAME);

    if (sessionData) {
      return Session.fromJSON(JSON.parse(sessionData));
    }

    return;
  }

  clearAccountSession() {
    if (!isBrowser()) return;

    localStorage.removeItem(SESSION_DATA_NAME);
  }

  showSwitchProfileModal() {
    this.switchProfileModalStore.open();
  }

  switchProfile(session: Session) {
    this.setAccountSession(
      new Session(session.id, session.name, session.type, session.role)
    );
    this.switchProfileModalStore.close();
  }
}

export const AccountContext = createContext<AccountStore>(null!);
export const useAccountContext = () => useContext(AccountContext);
