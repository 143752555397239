import { ApiTransport } from "@api/config/apiTransport";
import {
  AccountResponse,
  ChangePasswordRequest,
  ChangePasswordResponse,
  ExternalLoginRequest,
  LoginRequest,
  LoginResponse,
  LoginResult,
  RegisterRequest,
  SendOneTimePasscodeRequest,
  SendOneTimePasscodeResponse,
  UpdateAccountRequest,
  UpdateEmailRequest,
  VerifyEmailRequest,
  VerifyPhoneRequest,
} from "@root/utils/dtos";
import { Account } from "@root/utils/entities";

export class AccountService {
  private readonly _apiTransport: ApiTransport;

  constructor(sessionCookie?: string) {
    this._apiTransport = new ApiTransport(sessionCookie);
  }

  login = (request: LoginRequest): LoginResponse =>
    this._apiTransport.post<LoginResult>(`api/v1/account/login`, request);

  externalLogin = (request: ExternalLoginRequest): LoginResponse =>
    this._apiTransport.post<LoginResult>(`api/v1/account/sso`, request);

  register = (request: RegisterRequest): AccountResponse =>
    this._apiTransport.post<Account>(`api/v1/account/register`, request);

  verifyEmail = (request: VerifyEmailRequest): AccountResponse =>
    this._apiTransport.post<Account>(
      `api/v1/account/verification/email`,
      request
    );

  verifyPhone = (request: VerifyPhoneRequest): AccountResponse =>
    this._apiTransport.post<Account>(
      `api/v1/account/verification/phone`,
      request
    );

  sendOneTimePasscode = (
    request: SendOneTimePasscodeRequest
  ): SendOneTimePasscodeResponse =>
    this._apiTransport.post(`api/v1/account/verification`, request);

  getAccount = (): AccountResponse =>
    this._apiTransport.get<Account>(`api/v1/account`);

  updateAccount = (request: UpdateAccountRequest): AccountResponse =>
    this._apiTransport.post<Account>(`api/v1/account`, request);

  updateImage = (formData: FormData): AccountResponse =>
    this._apiTransport.postMultipart<Account>(`api/v1/account/image`, formData);

  updateEmail = (request: UpdateEmailRequest): AccountResponse =>
    this._apiTransport.post<Account>(`api/v1/account/email`, request);

  changePassword = (request: ChangePasswordRequest): ChangePasswordResponse =>
    this._apiTransport.post(`api/v1/account/password`, request);

  logout = () =>
    this._apiTransport.post<unknown | never>(`api/v1/account/logout`);
}

export const accountService = new AccountService();
